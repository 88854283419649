/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.0-BETA_DEV
* @date February 12, 2020
*/

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/

// NOTIFICATION
////////////////////////////////////


#immo_notification {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
	text-shadow: none;

	.alert {
		padding: 1em 2em;
		background-color: black;
		color: white;
		border-top: rem(1px) solid white;
		min-height: $barHeight;

		&:first-of-type {
			border-top: 0;
		}
	}

	.alert-blank {
		background-color: $dark;
		color: $light;
	}

	.alert-red {
		color: white;
		background-color: #DA4453;
		border-top-color: #2b2020;
	}

	.alert-green {
		color: white;
		background-color: #37BC9B;
		border-top-color: #e3e3e3;
	}

	.alert-orange {
		color: white;
		background-color: #E9573F;
		border-top-color: #e3e3e3;
	}

	.buttons {
		margin-bottom: rem(20px);
		display: block;
	}
}


// SUMMARY
////////////////////////////////////

.immo_summary {
	@extend .marginBottomMore;
	position: relative;
	display: flex;
	align-items: stretch;
	
	> div {
		display: flex;
		flex-direction: column;
		width: 100%;
	
		.cover {
			position: relative;
			height: 0;
            padding-top: 66.75%;
            position: relative;
            margin: 0;

			.imageCtn {
				display: block;
				margin: 0;
				border-radius: 0;
				overflow: hidden;

				position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
			}
			img {
				@include object-fit(cover, center center);
				height: 100%;
			}

			.immo_objectBadges {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 100;
				display: block;
				width: auto;

				mark {
					background: $dark;
					text-transform: uppercase;
					font-size: rem(11px);
					line-height: rem(30px);
					letter-spacing: 2px;
					height: rem(40px);
					font-weight: bold;
					color: $light;
					padding: rem(5px) rem(10px);
					display: inline-block;

					&:nth-child(odd) {
						background: darken($dark,5%);
					}
				}
			}

			.immo_bookmark {
				@extend .btn;
				cursor: pointer;
				position: absolute;
                top: 0;
                right: 0;
				width: 3.4em;
				height: 3.4em;
				line-height: 3.4em !important;
				padding: 0 !important;
				z-index: 100;
				display: flex !important;
				align-items: center;
				justify-content: center;

				&:before, &:after {
					content: none !important;
				}
				&:hover, &:focus {
					svg {
						transform: scale(1.1);
					}
				}
				svg {
					display: block;
                    transition: all 300ms ease-in-out;

					path {
						@extend %animatedTransform;
						stroke-width: 1.5px;
						stroke: $light;
						fill: none;
					}
				}
				&.wait {
					pointer-events: none !important;
					opacity: .7 !important;
				}
				&.active {
					svg {
						path {
							fill: $light;
						}
					}
				}
			}
		}
		.details {
			background: darken($light, 3%);
			color: $dark;
			width: 100%;
            height: 100%;
            padding: 0;
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;

			> *:last-child, blockquote > *:last-child {
				margin-bottom: 0 !important;
			}

            .infos {
                padding: 0;
                flex-grow: 1;
				padding: rem($baseGap) rem($baseGap) 0 rem($baseGap);

				.immo_tags, .objekttitel, h2, .h2, .location, > p {
					margin-bottom: rem($baseGap) !important;
				}
	
				.objekttitel, h2, .h2 {
					color: $dark;
					font-size: rem(20px);
					line-height: rem(30px);
					display: inline-block;
					width: 100%;
					padding: 0;
				}
	
				.location {
					display: block;
					width: 100%;
					position: relative;

					p {

						&:before {
							font-size: 20px;
							margin-right: .7rem;
							color: $dark;
						}

						display: flex;
						align-items: center;
						font-size: rem(15px);
						font-weight: 500!important;
						line-height: rem(25px);
						width: 100%;
						color: $dark;
						padding: 0 !important;
						margin: 0 !important;
					}
				}

				> p {
					color: $dark;
					font-size: rem(18px);
					margin-top: rem($baseGap*2);
				}

			} // .details.infos

			.openimmoTableCtn {
                margin-bottom: 0;
				flex-grow: 0;
				
                .openimmoTable {
                    border-top: none!important;
                    margin-bottom: 0!important;
                    display: flex;

                    tbody {
                    	overflow: auto;
                    	
                        tr {
                            display: flex!important;
                            flex-flow: row wrap;
                            padding: 0;
                            width: 100%;
                            border: none!important;
							min-width: 100%;

                            td {
                                border: 1px solid darken($medium, 3%) !important;
                                border: 1px solid darken($medium, 3%) !important;
                                padding: rem(18px) rem(22px)!important;
                                text-align: center;
                                background: lighten($medium, 4%); 
								font-size: rem(14px);
								color: #000000;
                                flex-grow: 1;
                                display: inline-block !important;
								min-width: 100%;
								font-family: $special;

								@include breakpoint(small) {
									min-width: 50%;
								}
								

                                span {
                                    &.not-visible {
                                        display: none;
                                    }

                                    &.before {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        line-height: 1;

                                        &:before {
                                            color:$dark;
                                            margin-right: .7rem;
                                            font-size: 20px;
                                            line-height: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
			}
		} //.details
	}
}

// DETAIL
////////////////////////////////////

#immo_detail {
	h1, h2, h3 {
		span {
			padding-top: rem(20px);
			display: block;
			font-size: 75%;
		}
	}
	address {
		font-style: normal;
	}
	> .row {
		@extend .space;

		+ .row {
			&:after {
				content: '';
				top: 0;
				position: absolute;
				border-top: $baseBorder;
				left: rem($baseGap);
				right: rem($baseGap);
				opacity: 0.45;
			}
		}
		&.noBottom {
			padding-bottom: 0 !important;

			+ .row {
				&:after {
					content: none;
				}
			}
		}
	}
	.compact {
		font-size: 1.4rem;
		font-weight: 500;
		padding-top: 0.3em;
		padding-bottom: 0.3em;

		span {
			display: block;
			font-weight: normal;
			font-size: 0.8rem;
			line-height: 1.3em;
		}
	}
	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
		width: 100%;
	}
	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}
	.swiper-slide {
		box-sizing: border-box;
		flex-shrink: 0;
		flex-grow: 0;
		width: auto;
	}
	.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
		height: auto;
	}
	.swiper-container-autoheight .swiper-wrapper {
		align-items: flex-start;
		transition-property: transform, height;
	}
	.swiper-pagination-fraction {
		position: absolute;
		top: 2em;
		right: 0;
		bottom: auto;
		left: auto;
		width: auto;
		background: $light;
		z-index: 1;
		color: $dark;
		line-height: 1.2em;
		font-size: rem($baseFontSize - 5);

		@include breakpoint(tiny) {
			font-size: rem($baseFontSize);
		}

		span {
			display: inline-block;
			padding: 0.5em 0.2em;
			font-weight: lighter;
			color: $dark;

			&.swiper-pagination-current {
				padding-left: 0.8em;
				font-weight: bold;
				font-size: 1.3em;

				@include breakpoint(small) {
					font-size: 1.5em;
				}
			}
			&.swiper-pagination-total {
				font-weight: 300;
				padding-right: 0.8em;
			}
		}
	}
	#immoCoverSliderThumb {
		height: 3rem;
		margin-top: 10px;

		@include breakpoint(tiny) {
			height: 4rem;
		}
		@include breakpoint(giant) {
			height: 6rem;
		}

		.swiper-slide, img {
			height: 100%;
			cursor: pointer;
		}
		img {
			width: auto;
			max-width: none;
		}
	}
	h2 {
		font-weight: bold;
	}
	.immo-info-list {
		columns: 3 rem(250px);
		column-gap: rem($baseGap / 2);

		dl {
			break-inside: avoid;
			margin-bottom: rem($baseGap / 2) !important;
			display: inline-block;
			width:100%;
			span {
				display: block;
			}
		}
	}
}

.immo_btn {
	@extend .btn;
}

.dyn-map-canvas {
	height: rem(300px);
	width: 100%;

	@include breakpoint(large) {
		height: rem(450px);
	}
}

.immo_oguloNotice {
	font-size: rem(12px);
	margin: 0 !important;
	padding: 0 !important;
}

.energybar {
	background: #028141;
	background: linear-gradient(to right, #028141 0%, #88bf3f 25%, #f9f23f 50%, #db7532 75%, #bd0e23 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#028141', endColorstr='#bd0e23', GradientType=1);
	width: 100%;
	height: rem(16px);
	// border-radius: rem(10px);
	margin-bottom: rem(50px);
	position: relative;
	z-index: 10000;
	
	&:before {
		content: "0";
		position: absolute;
		top: 100%;
		left: 0;
	}

	&:after {
		content: "250";
		position: absolute;
		top: 100%;
		right: 0;
	}

	&:not(:hover) {
		.knob {
			&:before,
			&:after {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.knob {
		width: rem(5px);
		height: rem(5px);
		background: $light;
		border: rem(10px) solid $dark;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		box-sizing: content-box;

		&:before, &:after {
			@extend %animatedTransform;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		&:after {
			content: attr(data-val);
			background: $dark;
			color: $light;
			padding: rem(10px);
			display: block;
			top: rem(-73px);
			min-width: rem(140px);
		}
		&:before {
			content: "";
			border-style: solid;
			border-width: rem(10px) rem(8px) 0 rem(8px);
			border-color: $dark transparent transparent transparent;
			top: -1.5rem;
			width: 0;
			height: 0;
		}
	}
}


// BOOKMARK + REQUEST
////////////////////////////////////

.immo_bookmark_status {
	p, span {
		font-size: rem(14px);
	}
}

.immoBtns {
	.btn {
		cursor: pointer;
		margin: rem($baseGap / 4) rem($baseGap / 2);

		&:first-of-type {
			margin-left: 0;
		}

		@include breakpoint(tiny, max) {
			margin: rem($baseGap / 4) 0;
			display: block;
		}
	}

	&.detail {
		.btn {
			width: 100%;
			margin: 0 0 rem($baseGap) 0;

			strong {
				font-weight: 500;
			}
			
			&.remove {
				background: #aa3434;
				color: #fff;
				
				&:hover, &:focus {
					background: #d95858;
					color: #fff;
				}
			}
			&.wait {
				cursor: wait !important;
				opacity: 0.5 !important;
				touch-action: none;
			}
		}
	}
}

.immoBookmarkNotice {
	font-size: rem(12px);
	line-height: rem(18px);
	margin: 0 !important;
	padding: 0 !important;
}

.immo_captcha {
	margin: rem(10px) 0 rem(35px) 0;
}

.immo_request {
	fieldset {
		border: none;
		padding-top: 1.5625rem!important;
		padding-bottom: 1.5625rem!important;
		margin: 0!important;
	}
	label, input, textarea, select, button, [type="submit"] {
		min-height: 0 !important;
		display: block !important;
	}

	[type="checkbox"] {
		float:left!important;
		display: inline-block!important;
		margin: 0.2rem .9375rem 0.5rem 0!important;
		padding: 0!important;
		width: .9375rem!important;
		height: .9375rem!important;
		&#Datenschutz {
			float: none!important;
		}
	}
	label {
		clear: unset !important;
		cursor: pointer;
	}
}

#immo_filter {
	width: calc(100% + #{rem($baseGap * 2)});
	margin-bottom: 1.8rem!important;
	margin-top: 2.8rem!important;

	&, .col {
		@include breakpoint(tiny) {
			padding: 0 rem($baseGap / 2);
		}
	}
	.col {
		@include breakpoint(tiny) {
			width: auto;
			flex-basis: auto;
			display: inline-block;
			flex-grow: 0;
		}

		@include breakpoint(medium) {
			display: flex;
		}

		&.margin-top {
			@include breakpoint(tiny, max) {
				margin-top: .7rem;
			}

			@include breakpoint(small) {
				margin-left: 1rem;
			}
			
		}
	}
	
	label {
		margin-bottom: 0.3rem;

		@include breakpoint(tiny) {
			margin-right: 1.3em;
			margin-bottom: 0;
			display: flex;
			align-items: center;
		}
	}

	.select {
		/* Reset Select */
		select {
			appearance: none;
			outline: 0;
			box-shadow: none;
			border: 0 !important;
			background-image: none;
			font-size: 17px!important;
			background: $grey-light;
			padding: .5rem 3rem .5rem .5rem;
			width: 100%;
			color: $dark;
		}

		/* Remove IE arrow */
		select::-ms-expand {
			display: none;
		}
	}

	/* Custom Select */
	.select {
		position: relative;
		display: flex;
		line-height: 3;
		overflow: hidden;
		font-size: 17px!important;

		&:after {
			@extend .icon;
			@extend .icon-after-angle-down;
			color: lighten($grey-dark, 10%);
			font-size: 22px;
			display: flex;
			align-items: center;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);

			@include breakpoint(medium, max) {
				transform: translateY(0%);
				background: rgba($light, .7);
				height: 100%;
				right: 0;
				top: 0;
				width: 40px;
				justify-content: center;
			}
			

		}
	}
/* 	select {
	@extend %animatedTransform;
	padding: 0.3em 0.9em 0.35em;
	background: $light;
	border: $baseBorder;
	color: $dark;
	font-family: $mainFont;
	resize: none;
	width: 100%;
	border-radius: 0;
	margin-bottom: 0.7rem;

	&:hover {
		border-color: $secondary;
	}
	&:focus {
		border-color: $primary;
		outline: none;
	}
} */
/* 	label, select {
	font-size: rem(11px);
	line-height: 1.3em;
	display: block;

	@include breakpoint(tiny) {
		display: inline-block;
		width: auto;
	}
} */
}

// MISC
////////////////////////////////////

.immo404 {
	label,a {
		display: inline-block;
	}
}


.immo-energy-notice {
	font-size: .8rem;
	color: lighten($dark,20);
}


.immo_tags {
	display: flex;
	width: calc(100% + 0.4rem);
	margin-left: -0.2rem;
	margin-right: -0.2rem;
	flex-wrap: wrap;

	body.openimmo-detail & {
		margin-bottom: rem($baseGap);
	}

	mark {
		flex-grow: 1;
		margin: 0.2rem;
		background: darken($light, 6%);
		color: $dark;
		padding: 0.7em;
		text-align: center;
		font-size: rem(12px);
		line-height: 1.3em;
		display: inline-block;

		&[data-mark="top angebot"], &[data-mark="rundgang"], &[data-mark="neu"] {
			background: darken($light, 12%);
			font-weight: bold;
		}
	}

	&.lowAmount {					
		mark {
			flex-grow: 0;
		}
	}
}

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/
