// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
	}

	body.edge & {
		overflow: visible;
	}

	&.immo-form {
		[type="text"],
		[type="tel"],
		[type="email"],
		[type="file"],
		input,
		textarea,
		select {
			background: $light;

			&:focus {
				background: darken($light, 2%);
				outline: none;
			}
		}
	}

	fieldset {
		border: none;

		&.no-margin {
			margin-bottom: rem($baseGap * 2)
		}
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		&:not([for="Datenschutz"]):not([for="Datenschutz1"]):not([for="Datenschutz2"]) {
			display: none;
		}

		&.showLabel {
			display: block!important
		}

		small {
			color: $green;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}

		&[for="Datenschutz"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	.select {
		/* Reset Select */
		select {
			appearance: none;
			outline: 0;
			box-shadow: none;
			border: 0 !important;
			background-image: none;
		}

		/* Remove IE arrow */
		select::-ms-expand {
			display: none;
		}
	}

	/* Custom Select */
	.select {
		position: relative;
		display: flex;
		line-height: 3;
		overflow: hidden;

		&:after {
			@extend .icon;
			@extend .icon-after-angle-down;
			color: lighten($grey-dark, 10%);
			font-size: 22px;
			display: flex;
			align-items: center;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			pointer-events: none;

			@include breakpoint(medium, max) {
				transform: translateY(0%);
				background: rgba($light, .7);
				height: calc(100% - 0.5rem);
				right: 0;
				top: 0;
				width: 40px;
				justify-content: center;
			}
			

		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $grey-light;
		border: none;
		color: $dark;
		display: block;
		font-family: $special;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 3%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}

		&.same-height {
			height: 10rem;
			min-height: 10rem;
			max-height: 10rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		// float: right;
		margin: 0 rem(15px) 0.5rem 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}

.h5.wide {
	width: 100%;
}

.flex-three {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	input {
		@include breakpoint(medium) {
			max-width: 100%;
		}

		&[type="file"] {
			line-height: 24px;
		}
		
	}
}
