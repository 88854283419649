/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.0-BETA_DEV
* @date February 12, 2020
*/

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/


#openimmoFixedButtons {
    z-index: 500;
    position: fixed;
    right: rem($baseGap);
    bottom: 0px;
    width: 100%;

    @include breakpoint(medium) {
        transform: rotate(-90deg);
        right: 0;
        transform-origin: right bottom;
        top: 10%;
        width: auto;
        height: auto;
        bottom: auto;
    } 

    @include breakpoint(large) {
        top: 20%;
    }

    @include breakpoint(large) {
        top: 20%;
    }

    @include breakpoint(fuller) {
        top: 15%;
    }

    

    ul {
        display: flex;
        width: auto;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        list-style: none;    

        @include breakpoint(medium) {
            height: auto;
        }
        
        li {
            padding: 0;
            margin: 0 15px 0 0;
            display: block;
            z-index: 800;

            &:last-child {
                margin: 0 5px 0 0;
            }

            label {
                line-height: 3em;
                font-size: 1rem;
                background: $light;
                color: $light;
                cursor: pointer;
                padding: 0;
                transition: all .3s;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: -1px -1px 15px rgba(0,0,0,0.35);
                overflow: hidden;

                @include breakpoint(giant) {
                    line-height: rem(85px)
                }
                
                &:hover,
                &:active,
                &:focus {
                    background-color: $light;
                    color: $secondary;
                }
            }

            &.search {
                label {
                    border-top-right-radius: 5px;
                    overflow: hidden;
                    border-top-left-radius: 5px;
                }

                span {
                    display: block;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    background: #778379;
                    font-family: $special;
                    font-size: rem(14px);

                    @include breakpoint(medium) {
                        font-size: rem(17px);
                    }
                    

                    @include breakpoint(giant) {
                        padding-left: rem(45px);
                        padding-right: rem(45px);
                        font-size: rem(20px);
                    }
                    
                }

                label:before {
                    content: '\f002'; // Change your icon in _custom.scss - an example has been placed there
                    font-family: $iconFont;
                    background: $light;
                    color: $darker;
                    width: 60px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include breakpoint(medium) {
                        width: 85px;                 
                        transform: rotate(80deg);
                        font-size: rem(20px);
                    }
                    @include breakpoint(giant) {
                        width: rem(105px);
                        font-size: rem(22px);
                    }
                }
            }


            &.bookmarks {
                margin-right: 0;

                label {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                }

                span {
                    display: none;
                }

                label:before {
                    width: 60px;
                    background: $light;
                    color: $dark;
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                    content: '\f006'; // Change your icon in _custom.scss - an example has been placed there
                    font-family: $iconFont;

                    @include breakpoint(medium) {
                        transform: rotate(20deg);
                        font-size: rem(20px);
                    }
                    @include breakpoint(giant) {
                        width: rem(90px);
                        font-size: rem(25px);
                    }                    
                }
            }
        }
    }
}

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/