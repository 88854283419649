/*! 
* @author Dominik Kressler
* @fileversion 2.5.1
* @packageversion 2.5.1
* @date 2022-05-19
* @description custom styles for the privacyControl
* @dependencies notification library styles
**/

:root {
    --pC-bgcolor: #333;
    --pC-bgcolor-badge: var(--pC-bgcolor);
    --pC-bgcolor-fields: #444;
    --pC-color: #fff;
    --pC-color-link: #eee;
    --pC-bgcolor-btn: #888a8c;
    --pC-color-btn: var(-pc-bgcolor);
    --pC-bgcolor-btn-confirm: #3bb54a;
    --pC-bgcolor-backdrop: rgba(58,58,58,0.85);
  }
  
  #privacyCtrl {
      background: var(--pC-bgcolor);
      border-radius: 4px;
      color: var(--pC-color);
      padding: 0;
      overflow: visible;

      .noteContent {
          padding: 0;
      }
  }
  html[data-privacy-control*="center"] #privacyCtrl {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  html[data-privacy-control*="backdrop"] #privacyCtrl {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  html[data-privacy-control*="backdrop"] #privacyCtrl:before {
      content: "";
      position: absolute;
      top: -50vh;
      bottom: -50vh;
      left: -50vw;
      right: -50vw;
      background-color: var(--pC-bgcolor-backdrop);
      z-index: -1;    
  } 
  #privacyCtrl, #privacyCtrl * {
      font-family: Arial, sans-serif;
  }
  #privacyCtrl.hide, #privacyCtrl .hide {
      opacity: 0;
      visibility: hidden;
  }
  #privacyCtrl.open {
      opacity: 1;
      visibility: visible;
  }
  .open #privacyCtrl {
      height: calc(100vh - 40px);
  }
  #privacy-controller {
      display: block;
      font-size: 11px;
      line-height: 1.3em;
      font-weight: normal;
  }
  .privacy-header {
      display: block;
      padding: 10px;
  }
  .privacy-header .privacy-text {
      padding-right: 20px;
  }
  .privacy-text {
      display: block;
      margin-bottom: 10px;
  }
  .privacy-buttons {
      display: flex;
  }
  .privacy-btn {
      width: 100%;
      display: flex;
      color: var(--pC-color-btn);
      background: var(--pC-bgcolor-btn);
      cursor: pointer;
      border-radius: 4px;
      padding-top: 6.6666666667px;
      padding-bottom: 6.6666666667px;
      padding-left: 12px;
      padding-right: 12px;
      flex-wrap: nowrap;
      white-space: nowrap;
      justify-content: center;
  }
  .privacy-btn:first-of-type {
      margin-right: 10px;
  }
  #privacy-confirm {
      background: var(--pC-bgcolor-btn-confirm);
      color: var(--pC-color);
  }
  .privacy-link {
      cursor: pointer;
      color: #eee;
      text-decoration: underline;
  }
  .privacy-fields {
      display: block;
      background: var(--pC-bgcolor-fields);
      max-height: 40vh;
      overflow-y: auto;
      border-left: 2px solid --pC-bgcolor;
      border-right: 2px solid --pC-bgcolor;
      height: 0;
      transition: 800ms height ease-in-out;
  }
  .privacy-controller.open .privacy-fields {
      height: 100vh;
  }
  .privacy-fields label {
      cursor: pointer;
      position: relative;
      display: block;
      width: 100%;
  }
  .privacy-fields label span {
      font-size: 1.2em;
      content: "";
      letter-spacing: 0;
      width: 2.2em;
      height: 1.2em;
      line-height: 1.2em;
      border-radius: 14px;
      display: block;
      text-align: center;
      background: #888a8c;
      color: transparent;
      margin-right: 10px;
      position: relative;
  }
  .privacy-fields label span:before {
      content: "";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: var(--pC-color);
      position: absolute;
      left: 4px;
      top: 3px;
      opacity: 1;
      transition: 150ms left ease-in-out;
      z-index: 10;
  }
  .privacy-fields label span:after {
      content: attr(data-off) " ";
      display: inline-block;
      position: absolute;
      left: calc(100% + 5px);
      color: #eee;
      font-size: 11px;
      word-break: normal;
  }
  .privacy-fields input:checked + span:before {
      left: 17px;
  }
  .privacy-fields input:checked + span {
      background: #3bb54a;
  }
  .privacy-fields input:checked + span:after {
      content: attr(data-on) " ";
  }
  .privacy-fields input {
      display: none;
  }
  .privacy-row {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
  }
  .privacy-row:not(:last-of-type) {
      border-bottom: 2px solid var(--pC-bgcolor);
  }
  .privacy-cell {
      width: 50%;
      hyphens: auto;
  }
  .privacy-cell:not(:last-of-type) {
      margin-bottom: 10px;
  }
  .privacy-footer {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
  }
  .privacy-footer .privacy-link {
      padding-left: 10px;
  }
  #privacy-reset {
      opacity: 0.5;
  }
  .privacyControlBadge {
      transform: rotate(90deg) translateX(-100%);
      display: inline-block;
      position: fixed;
      left: 0;
      bottom: 45px;
      transform-origin: bottom left;
      background: var(--pC-bgcolor-badge);
      color: var(--pC-color);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 12px;
      line-height: 12px;
      text-decoration: none;
      padding: 10px;
      transition: 300ms all ease-in-out;
      z-index:999;
      font-family:sans-serif
  }
  body.privacyControlIsOpen .privacyControlBadge {
      left: -100%;
  }
  
  a.granted.activated {
      display: none;
  }