#pageWrap {
	margin-top: rem(84.59px);

	@include breakpoint(large) {
	    margin-top: rem(103px + 102px);
	}
	
	@include breakpoint(giant) {
	    margin-top: rem(119px + 104px);
	}

	body:not(.index) & { 
		@include breakpoint(large) {
			margin-top: rem(102px + 62px)
		}

		@include breakpoint(giant) {
			margin-top: rem(119px + 62px)
		}
	}
}

[id^='provenexpert_circle_widget_'] {
	body:not(.index) & {
		@include breakpoint(tiny) {
			display: inline-block !important;
			margin-left: rem($baseGap);
		}
	}
}

#openimmoFixedButtons {
	body.cmsBackend & {
		display: none;
	}

	body.index & {
		transform: translateY(100%);
		transition: all 300ms ease-in-out;

		&.showit {
			transform: translateX(0);
		}

		@include breakpoint(medium) {
			transform: translateX(100%) rotate(-90deg);
			transition: all 300ms ease-in-out;
	
			&.showit {
				transform: translateX(0) rotate(-90deg);
			}
		}
	}
}

.padding-outter {
	padding: 4rem 0;
	word-break: break-word;

	@include breakpoint(small) {
		padding: 6rem 0;
	}

	@include breakpoint(large) {
		padding: 9rem 0;
	}

	@include breakpoint(cut) {
		padding: rem(150px) 0;
	}

	&.bigger {
		@include breakpoint(cut) {
			padding: rem(240px) 0;
		}
	}
}

.grey-outter, .outter-grey {
	background: $grey-dark;
}

.grey-outter-light, .outter-grey-light {
	background: $medium;

	.hr {
		background: darken($grey-medium, 7%);
	}
}

.videoBox {
	@include breakpoint(small, max) {
		margin-bottom: rem($baseLineHeight);
	}

	&:not(:last-child):not(:nth-last-child(2)) {
		@include breakpoint(small) {
			margin-bottom: rem($baseLineHeight);
		}
	}
}

.top-bar {
	background: $light;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1001;
	display: none;

	@include breakpoint(large) {
		display: flex;
	}

	body:not(.index) & {
		.row {
			.branding {
				img {
					width: 100%!important;
				}
			}
		}
	}

	&.smaller {
		.row {
			.branding {
				img {
					width: 100%!important;
				}
			}
		}
	}

	.row {
		padding: rem(15px) rem($baseGap);
		justify-content: space-between;

		@include breakpoint(cut) {
			padding: rem(23px) rem($baseGap);
		}
		

		.branding {
			img {
				width: 100%;
				height: auto;
				transition: all 300ms ease-in-out;

				@include breakpoint(cut) {
					width: 205px;			
				}
				
			}
		}

		> div {
			display: flex;
			align-items: center;

			ul {

				&.contact-data {
					display: flex;
					margin-right: 3rem;

					@include breakpoint(cut) {
						margin-right: rem(60px);
					}
					

					li {
						font-size: 16px;
						line-height: 1em;

						a, span {
							font-family: $special;
						}

						strong {
							margin-right: .4rem;
						}

						@include breakpoint(cut) {
							font-size: 18px;
						}
						
						&:first-child {
							margin-right: 1rem;

							@include breakpoint(cut) {
								margin-right: rem(30px);						
							}
						}
					}
				}

				&.socials {
					display: flex;

					li {
						line-height: 1em;

						&:first-child {
							margin-right: .6rem;
						}

						a{
							img {
								height: rem(22px);
								width: auto;
							}

							&:hover {
								opacity: .6;
							}
						}
					}
				}
			}
		}
	}
}

.fixedContacts {
	&:before, &:after {
		transition: all 300ms ease-in-out;
	}

	.smaller {
		&:before, &:after {
			box-shadow: 3px -1px 12px 0px rgba(0,0,0,0.2);
		}
	}
}
// HEADER
//////////////////////////////
.immobilien-form {
	display: block;
	background: $grey-light;
	margin-top: 4.5rem;
	padding: 1.5rem;
	border-radius: 5px;
	position: relative;
	border-top-left-radius: 0px;

	@include breakpoint(tiny) {
		padding: 2rem;
	}

	@include breakpoint(small) {
		margin-top: 6.5rem;
		padding: 3rem;
	}

	@include breakpoint(medium) {
		margin-top: 0;
		padding: 2rem;
		padding-top: #{2rem + rem($baseGap)};
		margin-left: auto;
		margin-right: 0;
		display: table;
	}

	@include breakpoint(cut) {
		padding: rem(62px);
		padding-top: rem(62px + $baseGap * 2);
	}

	.modalGallery {
		@include breakpoint(tiny) {
			float: right;
		}

		a {
			margin-bottom: rem($baseLineHeight / 1.5);
			display: block;
			transition: opacity 366ms ease-in-out;
			max-width: rem(188px);
	
			@include breakpoint(small) {
				// position: absolute;
				// margin-bottom: 0;
				bottom: calc(100% - #{rem($baseGap * 6)});
				// right: rem($baseGap);
			}

			@include breakpoint(medium) {
				bottom: calc(100% - #{rem($baseGap)});
				right: rem($baseGap);
				position: absolute;
				margin-bottom: 0;
			}

			@include breakpoint(cut) {
				bottom: calc(100% - #{rem($baseGap * 2)});
				right: rem($baseGap * 2);
			}

			&:hover, &:active, &:focus {
				opacity: 0.875;
			}
		}
	}
}

#header {
	position: relative;
	width: 100%;
	background-size:cover;

	body.cmsBackend & {
		display: none;
	}

	body.openimmo-detail &, body.openimmo-request & {
		display: none;
	}

	>.row {
		padding: 6rem 0 3rem;

		@include breakpoint(medium) {
			align-items: flex-end;
			padding: 14rem 0 6rem;
		}

		body:not(.index) & {
			padding: 6rem 0 3rem;
		}

		@include breakpoint(cut) {
			padding: rem(320px) 0 rem(80px);
		}
		
		@include breakpoint(huge) {
			padding: rem(420px) 0 rem(80px);
		}
	}
	
	@include responsiveImage('layout/bgHeader.jpg', (tiny, large), false);
	

	#pe_badge_sputgvcb {
		margin-top: rem($baseLineHeight / 1.5);
		display: table;

		@include breakpoint(giant) {
			position: absolute;
			right: rem($baseGap);
			top: rem($baseGap);
		}

		body:not(.index) & {
			@include breakpoint(medium) {
				position: absolute;
				right: rem($baseGap + 54px);
				top: rem($baseGap);	
			}

			@include breakpoint(giant) {
				right: rem($baseGap + 84px);
			}

			@include breakpoint(full) {
				right: rem($baseGap);
			}
		}
	}

	.padding-bottom {
		body.index & {
			@include breakpoint(medium) {
				padding-bottom: 2rem;
			}

			@include breakpoint(cut) {
				padding-bottom: rem(42px);
			}
		}

		body:not(.index) & {
			max-width: 100%;
			flex-basis: 100%;
		}
		
	}

	blockquote {
		color: $light;
		font-family: $special;
		font-size: rem(18px);
		line-height: 1.3em;
		margin-bottom: 1.5rem;

		@include breakpoint(tiny) {
			font-size: rem(20px);
		}

		@include breakpoint(small) {
			font-size: rem(22px);
			margin-bottom: 2.5rem;
		}

		@include breakpoint(cut) {
			font-size: rem(24px);
			margin-bottom: rem(50px);
		}

		body:not(.index) & {
			display: inline-block;
		}

		span {
			font-weight: 500;
			display: block;
			font-size: rem(34px);
			line-height: 1.3em;

			body:not(.index) & {
				font-size: rem(35px);
				margin-bottom: .5rem;
			}

			@include breakpoint(tiny) {
				font-size: rem(48px);
			}

			@include breakpoint(small) {
				font-size: rem(57px);
				margin-bottom: 1rem;
			}

			@include breakpoint(cut) {
				margin-bottom: 1.4rem;
				font-size: rem(100px);
				line-height: 1.15em;

				body:not(.index) & {
					font-size: rem(40px)
				}
			}		
		}
	}
	.claim {
		.row.teaser {
			display: flex;
			justify-content: space-around ;
			@include breakpoint (large) {
				justify-content: space-between;
			}
			.Orte {
				.col {
					// margin-top: rem(14px);
					max-width: 170px;
				}
				a {
					width: 134px;
					height: 134px;
					position: relative;
					display: flex;
					&:hover .overlay {
						transition: opacity 366ms ease-in-out;
						opacity: 0%;
					}
					.teaser-img {
						border-radius: 50%;
						display: block;
						width: 134px;
						height: 134px;
					}
					.overlay {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						height: 134px;
						width: 134px;
						transition: .5s ease;
						background-color: #0000003d;
						border-radius: 50%;
						opacity: 100%;
						display: flex;
    					justify-content: center;
   						align-items: center;
						.text {
							color: white;
							line-height: normal;
							font-size: 15px!important;
							position: absolute;
							// top: 50%;
							// left: 50%;
							// transform: translate(-50%, -50%);
							text-align: center;
							font-weight: 400;
							text-shadow: 0 0 3px #313131;
							font-size: rem(16.5px);
							letter-spacing: 0.5px;

						}
					}
				}
			}
		}

	}

	.btn {
		body.ueberUnsIndex & {
			display: none;
		}
	}
}
body:not(.home) {
	.col.claim {
		@include breakpoint (small) {
			display: flex;
		}
		blockquote {
			margin-top: 1.5em;
			width: 100%;
			@include breakpoint (small) {
				width: 40%;
			}
			@include breakpoint (huge) {
				width: 34%;
			}
		}
		.row.teaser {
			display: flex;
			justify-content: center !important;
			@include breakpoint (medium) {
				justify-content: space-around !important;
				width: 60%;
			}
			@include breakpoint (huge) {
				width: 70%;
			}
			.Orte {
				@include breakpoint (medium, max) {
					display: none;
				}
			}
		}
	}
}
.flyerBtn {
	background-image: url('/images/0_house-1353389_1280.jpg');
	backdrop-filter: blur(5px);
}

.first-grey {
	>.row {
		.col {

			display: flex;
			flex-flow: column;
			align-items: center;
			padding: 3rem $baseGap;

			
			@include breakpoint(small) {
				padding-left: 3rem;
				padding-right: 3rem;
			}

			@include breakpoint(medium) {
				padding: rem(40px) $baseGap;
				align-items: flex-start;

			}

			@include breakpoint(fuller) {
				flex-flow: row;
				align-items: flex-start;
			}

			.flex-it {
				display: flex;
				flex-flow: column;
				align-items: center;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;

				
				@include breakpoint(medium) {
					align-items: flex-start;
					margin-top: -2rem;
				}
				@include breakpoint(giant) {
					margin-top: -.5rem;
				}
				@include breakpoint(fuller) {
					margin-top: 2rem;
				}
			} 

			&.corona-cont {
				position: relative;


				p, a, svg {
					position: relative;
					z-index: 2;
					
				}
				
				&:before {
					content:'';
					z-index: 1;
					height: 100%;
					right: 0;
					top: 0;
					position: absolute;
					display: block;
					background-color: $green;
					width: 100vw;
					
					@include breakpoint(large) {
						width: 50vw;
					}
				}
			}

			&:not(.corona-cont) {

			}
			

			

			svg {
				margin-bottom: 2rem;
				flex-shrink: 0;

				@include breakpoint(medium) {			
					margin-right: 2rem;			
				}

				@include breakpoint(fuller) {
					margin-right: rem(44px);			
				}
			}

			p {
				color: $grey-light;
				font-family: $special;
				text-align: center;
				margin-bottom: 0!important;	

				@include breakpoint(medium) {
					text-align: left;
					font-size: rem(17px);		
				}

				@include breakpoint(fuller) {
					font-size: rem(20px);
				}
			}

			.outline {
				flex-shrink: 0;
				margin-top: 1.7rem;

				&:hover {
					background: lighten($grey-dark, 10%)
				}
			}
		}
	}
}

.second-grey {
	padding: 4rem 0;

	@include breakpoint(large) {
		padding: rem(100px) 0;
	}
	
	>.row {
		.flex-btns {
			@include breakpoint(small) {
				display: flex;
				flex-flow: row wrap;
			}
			
			a {
				position: relative;
				overflow: hidden;
				background: $green-dark;
				display: block;
				
				&:after{
					content:'';
					height: 100%;
					width: 100%;
					background: darken($green-dark, 2%);
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					transform: translateX(-100%);
					transition: all 300ms ease-in-out;
				}

				svg {
					transition: all 300ms ease-in-out;
					content:'';
					height: 42px;
					width: 42px;
					display: block;
					margin-right: 1rem;
					z-index: 9;

					path {
						transition: all 300ms ease-in-out;
					}
				}

				&:hover {
					&:after {
						transform: translateX(0);
					}

					svg {
						path {
							fill: $light;
						}
					}
				}

				span {
					padding: 1rem;
					display: flex;
					color: $light;
					font-family: $special;
					font-weight: 500;
					text-decoration: none;
					align-items: center;
					position: relative;
					overflow: hidden;
					z-index: 9;

					@include breakpoint(tiny) {
						padding: 2rem;
					}

					@include breakpoint(small) {
						justify-content: center;
					}

					@include breakpoint(large) {
						padding: rem(35px);
						font-size: rem(20px);
					}

				}
				
				&:nth-of-type(2) {
					background: $green-medium;
					
					&:after {
						background: darken($green-medium, 3%)
					}
				}

				&:nth-of-type(3) {
					background: $green;

					&:after {
						background: darken($green, 5%)
					}

					@include breakpoint(small) {
						width: 100%;
					}

					@include breakpoint(large) {
						width: calc(100%  / 3);
					}
				}

				&:not(:last-child) {
					@include breakpoint(small) {
						width: 50%;
					}

					@include breakpoint(large) {
						width: calc(100%  / 3);
					}
				}
			}
		}
	}
}

.bigger {
	img {
		margin-bottom: 0;
	}

	.margin-top-img {

		@include breakpoint(small) {
			margin-top: .6rem;
		}
		
	}
}

.image-cont-margin {
	@include breakpoint(small) {
		margin-top: 0rem;
	}
	
	@include breakpoint(large) {
		margin-top: 1.2rem;
	}
	
}

.immo-cont {
	position: relative;

	body.cmsBackend & {
		display: none;
	}

	&:after {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		display: block;
		background: $medium;
		z-index: -1;
	}
	
	.outter-grey {
		padding-bottom: 9rem;

		@include breakpoint(huge) {
			padding-bottom: 230px;
		}
		
		.headline {
			text-align: center;
			font-family: $special;
			color: $light;
			font-size: rem(22px);
			line-height: 1.2em;

			strong {
				@include breakpoint(small) {
					display: block;			
				}
			}

			@include breakpoint(tiny) {
				font-size: rem(25px);		
			}
			
			@include breakpoint(cut) {
				line-height: 1.3em;
				font-size: rem(48px);
			}
		}
	}

	.outter-grey-light {
		padding-top: 0;
		background: transparent; 

		.padding-outter {
			padding: 0;
		}

		.row {
			margin-top: -150px;

			> div {
				position: relative;
				width: 100%;
				box-sizing: border-box;
				overflow: hidden;
				padding: 0 rem($baseGap);

				@include breakpoint(tiny) {
					padding-left: 2.7rem;
					padding-right: 2.7rem;
				}

				@include breakpoint(small) {
					padding-left: 3.7rem;
					padding-right: 3.7rem;
				}

				.swiper-button-next, .swiper-button-prev {
					margin: 0; 
					padding: 0; 
					width: 25px;
					height: 35px;
					background: $light;
					box-shadow: 3px -1px 12px 0px rgba(0,0,0,0.4);
					transition: all 300ms ease-in-out;

					&:hover {
						opacity: .6;
					}

					@include breakpoint(tiny) {
						background: transparent;
						box-shadow: none;
					}
					
					
					&:before {
						@extend .icon;
						color: $dark;
						font-size: 30px;
					}

					&:after {
						display: none;
					}
				}

				.swiper-button-next {
					margin-left: .7rem;

					&:before {
						@extend .icon-angle-right;
					}
				}

				.swiper-button-prev {
					&:before {
						@extend .icon-angle-left;
					}
				}
			}

			.col {
				display: flex;
				justify-content: center;
				flex-flow: row wrap;

				&.btn-col {
					margin-top: 2.5rem;
					
					a {
						width: 100%;

						@include breakpoint(small) {
							width: auto;
							margin-top: 0;
						}
						

						&:nth-of-type(2) {
							margin-top: .76rem;

							@include breakpoint(small) {
								margin-top: 0;
								margin-left: .8rem;
							
							}
							
						}
					}
				}
			}
		}


		.swiper-container {
			width: 100%;
			overflow: visible;

			.swiper-wrapper {
				.swiper-slide {
					box-sizing: border-box;
					margin-top: 30px;
					margin-bottom: 30px;
					opacity: 0;
					visibility: hidden;
					transition: all 500ms ease-in-out;
					height: auto;

					> div, .immo_summary{
						height: 100%;
					}

					&.swiper-slide-active {
						transition: all 500ms ease-in-out;
						opacity: 1;
						visibility: visible;

						@include breakpoint(medium) {
							&, &.swiper-slide-active + .swiper-slide {
								transition: all 500ms ease-in-out;
								opacity: 1;
								visibility: visible;
							}
						}
					}

					.inner{
						box-shadow: 3px -1px 12px 0px rgba(0,0,0,0.3);
						display: block;

						table {
							tbody {
								overflow: auto;
							}
						}	

						.btn {
							margin-top: 0;
						}					
					}
				}
			}
		}


		.btn {
			margin-top: 2rem;

			@include breakpoint(small) {
				margin-top: 3rem;		
			}

			@include breakpoint(giant) {
				margin-top: rem(90px);		
			}
		}
	}
}

.teaser-cont {
	body.cmsBackend & {
		display: none;
	}

	.headline {
		text-align: center;
		font-family: $special;
		font-size: rem(20px);
		font-weight: 300;
		line-height: 1.3em;

		@include breakpoint(small) {
			margin-bottom: 3rem;
		}

		@include breakpoint(large) {
			margin-bottom: 4rem;
			padding: 0 rem(160px);
		}
		
		@include breakpoint(cut) {
			line-height: 1.4em;
			font-size: rem(30px);
			margin-bottom: rem(90px);
		}
	}

	.more-margin {
		@include breakpoint(giant) {
			margin-bottom: 3.5rem!important;
		}
		
	}

	.col {
		&.small-6 {
			&:not(:last-child) {
				
				margin-bottom: 1rem;

				@include breakpoint(small) {
					margin-bottom: rem($baseGap  * 2);
				}
			}

			body.index & {
				&:nth-of-type(5), &:nth-of-type(6) {
					@include breakpoint(large) {
						margin-bottom: 0;
					}
				}
			}

			body.immobilienIndex & {
				margin-bottom: 1rem;

				@include breakpoint(small) {
					margin-bottom: rem($baseGap  * 2);
				}
			}

			body.aktuelles & {
				&:last-child {
					@include breakpoint(large) {
						margin-bottom: rem($baseGap  * 2);
					}
				}
			}
		}

		>a {
			text-decoration: none;
			color: $dark;
			font-size: rem(16px);
			line-height: rem(24px);
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: flex-start;
			height: 100%;

			&.grey {
				background: $medium;

				.descr {
					padding: 2rem 1rem;
					display: block;
					background: $medium;
					font-size: rem(16px);
					line-height: rem(28px);
					flex-grow: 1;

					@include breakpoint(tiny) {
						padding: 2rem;
					}
					

					@include breakpoint(large) {
						padding: rem(60px) rem(40px);
					}
				}
			}

			&:hover {
				span {
					&.btn {
						background: $grey-dark;
					}
				}
			}

			img {
				margin-bottom: 0;
			}

			span {
				width: 100%;

				&.descr {
					padding: 2rem 1rem;
					display: block;
					background: $light;
					font-size: rem(16px);
					line-height: rem(28px);
					flex-grow: 1;

					@include breakpoint(large) {
						padding: rem(60px) rem(40px);
					}
				}

				&.btn {
					@extend .btn;
					display: flex;
					width: 100%;
					text-align: center;
					justify-content: center;

					@include breakpoint(cut) {
						padding: 20px 20px;
					}
				}
			}
		}
	}
}

.third-cont {
	@include breakpoint(medium,max) {
		padding-bottom: 0;
	}

	.bigger-margin {
		margin-top: 2rem;

		@include breakpoint(small) {
			margin-top: 3rem;	
		}

		@include breakpoint(giant) {
			margin-top: 4rem;	
		}

		@include breakpoint(huge) {
			margin-top: 6rem;	
		}

		@include breakpoint(cut) {
			margin-top: 7rem;	
		}
	}
	

	.inner-grey {
		background: $medium;
		padding: rem(25px);
		text-align: center;

		@include breakpoint(large) {
			padding: rem(50px);
		}

		.flex-list {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin-bottom: 0;

			li {
				display: flex;
				align-items: center;

				@include breakpoint(small, max) {
					width: 100%;
					justify-content: center;
					padding-left: 0;
				}
				

				&:not(:last-child) {
					@include breakpoint(small) {
						margin-right: .8rem;
					}
					

					@include breakpoint(medium) {
						margin-right: 1.2rem;
					}

					@include breakpoint(cut) {
						margin-right: 2.2rem;
					}
					
				}

				@include breakpoint(large) {
					margin-bottom: 0;
					line-height: 1;
				}

				@include breakpoint(cut) {
					margin-right: rem(30px);
				}
			}
		}
	}
}

.after-img {
	position: relative;
	padding: 4rem 0 20rem;
	margin-bottom: -4rem;
	transition: all 300ms ease-in-out;

	@include breakpoint(tiny) {
		padding: 4rem 0 34rem;
	}

	@include breakpoint(medium) {
		padding: 6rem 0 6rem;
	}

	@include breakpoint(large) {
		padding: rem(130px) 0 rem(280px);
		margin-bottom: -120px;
	}

	.medium-5 {
		@include breakpoint(giant) {
			padding-right: 4rem;
		}	
	}

	&.isOnScreen {
		&:after {
			transition: all 300ms ease-in-out;
			content:'';
			height: 19rem;
			width: calc(100% - #{$baseGap} * 2);
			display: block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transition: all 300ms ease-in-out;
			background: url('/images/layout/cont-img.jpg') no-repeat center/cover;
			transform: translate(-50%, 0px);

			@include breakpoint(tiny) {
				height: 30rem;	
			}

			@include breakpoint(medium) {
			    top: 0;
				right: $baseGap;
				left: auto;
				bottom: auto;
				transform: none;
				width: 53%;
				height: 100%;		
			}			
		}
	}
}

.last-cont {
	padding-top: 8rem;

	@include breakpoint(medium) {
		padding-top: 12rem;
	}

	@include breakpoint(large) {
		padding-top: rem(260px)
	}

	>.row {
		@include breakpoint(medium, max) {
			justify-content: center;
		}
		
	}

	.h3 {
		text-align: center;
		padding: 0 1rem;

		@include breakpoint(large) {
			padding: 0 3rem;
		}

		@include breakpoint(cut) {
			padding: 0 4rem;
		}
	}

	p {
		&:not(.h3) {
			font-family: $special;
			text-align: center;
			font-size: rem(20px);
			margin-bottom: 3rem;

			@include breakpoint(medium) {
				margin-bottom: 6rem;		
			}
			

			@include breakpoint(large) {
				font-size: rem(24px);
				margin-bottom: rem(130px);
			}
		}
	}

	.referenz-teaser {
		p {
			text-align: left;
			font-size: rem(18px);
			line-height: rem(24px);
			color: #707378;
			margin-bottom: .5rem!important;		

			@include breakpoint(medium) {
				    margin-bottom: .5rem!important;		
			}
		}

		img{
			margin-bottom: rem(35px);
		}

		a {
			font-family: $special;
			font-weight: 500;
		}
	}

	.centered {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
		

		.btn {

			@include breakpoint(large) {
				padding: rem(28px) rem(85px);
			}
			
		}

		@include breakpoint(medium) {
			margin-top: 4rem;
		}
		

		@include breakpoint(large) {
			margin-top: rem(54px);
		}

		@include breakpoint(huge) {
			margin-top: rem(74px);
		}
		
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	blockquote {
		display: table;
		position: relative;
		line-height: 0.2;
	  
		&:before, &:after {
		  display: inline-block;
		  font-size: 5em;
		  opacity: 0.25;
		}
	  
		&:before {
			content: "„";
			margin-right: 0.125em;
		}
		
		&:after {
			content: "”";
			transform: translateY(100%);
		  margin-left: 0.125em;
		}
	  }

	  .alignCenter blockquote {
		margin-left: auto;
		margin-right: auto;
	  }

	  .alignRight blockquote {
		  margin-left: auto;
	  }
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.googleMaps:not(.videoTag) {
	height: 100%;

	iframe {
		height: 300px;

		@include breakpoint(medium) {
			height: 100%;
		}
		
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $grey-dark;

	> .row {
		padding: 4rem 0 ;

		@include breakpoint(cut) {
			padding: rem(165px) 0 rem(90px);
		}
	}
	
	.flex-boxes {
		p, a, span, li, address{
			font-family: $special;
		}

		@include breakpoint(huge) {
			display: flex;
		}
		

		.left {
			color: $grey-light;

			@include breakpoint(medium) {
				margin-bottom: 5rem;
			}
			
			@include breakpoint(small) {
				display: flex;
				flex-flow: row wrap;
				align-items: flex-start;	
				justify-content: space-between;	
			}

			@include breakpoint(medium) {
				justify-content: flex-start;	
			}
			

			@include breakpoint(huge) {
				flex-flow: column;
				width: 33%;
			}
			
			

			a {
				color: $grey-light;
			}

			> p{
				font-size: rem(20px);
				color: $grey-light;
				font-weight: 300;

				@include breakpoint(tiny) {
					width: 100%;
				}

				@include breakpoint(large) {
					font-size: rem(24px);
				}
			}

			ul {
				li {
					strong {
						margin-right: .7rem;
					}
				}
			}

			dl {
				margin-top: 1.5rem;

				@include breakpoint(small) {
					margin-top: 0;
					width: 48%;
				}

				@include breakpoint(medium) {
					margin-left: 3rem;
				}

				@include breakpoint(huge) {
					margin-left: 0;
					width: auto;
					margin-top: rem(34px);
				}
				
				dt, dd {
					padding: 0;
					margin: 0;
					width: auto;
					background: transparent;
				}

				dt {
					padding-right: .8rem;
					font-weight: 500;
				}

				dd {
					position: relative;

					&:after {
						content:'sowie nach Vereinbarung';
						position: absolute;
						left: 0;
						top: 100%;
						display: block;
						font-size: 14px;
						font-family: $special;
						white-space: nowrap;
						line-height: 1;

						@include breakpoint(tiny) {
							font-size: 18px;
						}
						
					}
				}
			}
		}

		> .sitemap {
			@include breakpoint(tiny) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				flex-grow: 1;
			}

			@include breakpoint(medium, max) {
				display: none;
			}

			@include breakpoint(medium) {
				padding-right: 4rem;
			}
			

			@include breakpoint(giant) {
				padding-right: 10rem;
			}

			@include breakpoint(huge) {
				padding-right: rem($baseGap);
			}
		
			
			.sitemap-list {

				&:not(:last-child ){
					margin-bottom: 1.5rem;
				}

				@include breakpoint(tiny) {
					width: 48%;
				}

				@include breakpoint(medium) {
					width: auto;
				}
				

				p {
					&.headline {
						font-weight: 300;
						color: $grey-light;
						font-size: rem(20px);
						text-decoration: none;

						@include breakpoint(large) {
							font-size: rem(24px);
							margin-bottom: .9rem;
						}

						a {
							font-weight: 300;
							color: $grey-light;
							font-size: rem(20px);
							text-decoration: none;

							@include breakpoint(large) {
								margin-bottom: .9rem;
								font-size: rem(24px);
							}
						}
					}
				}

				.naviAdd {
					display: flex;
					flex-flow: column;
					align-items: flex-start;

					li {
						margin-right: 0;

						a, span {
							color: $grey-light3;
							font-weight: 300;
						}

						> span {
							&.immobilien {
								display: none;
							}
						}

						&:hover, &.active {
							> a {
								color: $grey-light;
							}
						}
					}
				}

				&.sitemap {
					.naviAdd {
					}
				}
			}
		}
	}

	.naviAdd {
		display: flex;
		flex-flow: row wrap;
		
		

		li {
			margin-bottom: .3rem;
			line-height: 1.6em;

			@include breakpoint(small, max) {
				width: 100%;
			}
			

			@include breakpoint(small) {
				margin-bottom: .4rem;
			}

			@include breakpoint(large) {
				margin-bottom: .7rem;
			}

			> a, span {
				&.active {
					color: $grey-light!important;

					&:after {
						width: 100%;
					}		
				}
			}

			&:hover, &.active {
				> a, span {
					color: $grey-light!important;
					&:after {
						width: 100%;
					}
				}
			}

			ul {
				li {
					> a, span {

						&.active {
							color: $grey-light!important;
							&:after {
								width: 100%;
							}		
						}
					}		
				}
			}

			&.leistungen {
				body.leistungen & {
					> a {
						color: $grey-light!important;
						&:after {
							width: 100%;
						}
					}
				}
			}


			> a, span {
				color: $grey-light3;
				text-decoration: none;
				display: inline-block;
				font-size: 15px;
				line-height: 1.2em;

				@include breakpoint(small, max) {
					display: block;
				}


				@include breakpoint(small) {
					font-size: 17px;
					line-height: 1.3em;
				}

				@include breakpoint(medium) {
					font-size: 16px;
				}

				@include breakpoint(large) {
					line-height: 1.3em;
				}

				@include breakpoint(giant) {
					font-size: 17px;
				}

				&.fuerSieVorOrt, &.leistungen {
					margin-bottom: .3rem;
					line-height: 1.6em;

					@include breakpoint(small) {
						margin-bottom: .4rem;
					}

					@include breakpoint(large) {
						margin-bottom: .7rem;
					}
				}
				
				&:after {
					content:'';
					height: 1px;
					width: 0;
					display: block;
					left: 0;
					top: 100%;
					background: $grey-light3;
					transition: all 300ms ease-in-out;
				}
			}

			li {
				margin-bottom: .3rem;
				line-height: 1.6em;

				@include breakpoint(small) {
					margin-bottom: .4rem;
				}

				@include breakpoint(large) {
					margin-bottom: .7rem;
				}

				> a.wertermittlung {
					margin-bottom: .3rem;
					line-height: 1.6em;
				}
			}

			&:hover, &.active {
				> a, span {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.bottom-footer {
		background: $medium;
		

		.row {
			padding: 2rem rem($baseGap) 6rem;
			justify-content: center;		

			@include breakpoint(medium) {
				padding: 2rem rem($baseGap);
			}
			

			@include breakpoint(large) {
				justify-content: center;		
				align-items: center;
				padding-top: 4.125rem;
				padding-bottom: 4.125rem;
			}

			img {
				margin-bottom: 2.5rem;

				@include breakpoint(large) {
					margin-bottom: 0;
				}
				

				&.isOnScreen {
					background: none;
				}
			}

			.naviAdd {
				margin: auto; 

				@include breakpoint(medium) {
					padding-top: 2.5rem;
				}
				@include breakpoint(large) {
					padding-top: 0rem;
					//padding-bottom: 2.5rem;
					margin-top: 3rem; 
				}
			


				li {
					margin-right: 0;
					margin-bottom: 0;
					
					@include breakpoint(small, max) {
						&:not(:last-child) {
							a {
								border-bottom: 1px solid darken($medium, 15%);
							}
						}
					}


					&:last-child {
						a {
   
							@include breakpoint(large) {
								padding-right: 0;						
							}
						}
					}

					&:hover,&.active {
						a {
							color: $green!important;
						}
					}
					
					a {
						color: $darker;
						font-family: $special;
						position: relative;
						text-decoration: none;
						font-weight: 300;
						font-size: rem(19px);
						padding: .75rem rem(20px);

						@include breakpoint(large) {
							padding: 0 rem(20px);
						}
						

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
}

////////////// SUBPAGES

body.agb, body.widerruf {
	h4 {
		margin-bottom: 1rem;
	}
}

body.widerruf {
	.grey {
		padding: rem($baseGap);
		background: $medium;

		@include breakpoint(cut) {
			padding: 2rem;
		}

		.btn {
			padding-left: 30px;
			padding-right: 30px;
		}
		
	}
}

.columns {
	@include breakpoint(small) {
		columns: 2;
	}

	li {
		break-inside: avoid;
	}

	h6 + p {
		margin-bottom: 0;
	}
	
}

.inner-grey {
	padding: rem($baseGap * 2);
	background: $medium;
	border-radius: 4px;
	height: 100%;

	&.auto-height {
		height: auto;
	}

	@include breakpoint(cut) {
		padding: 2rem;
	}

}

.col {
	&.margin-bottom {
		margin-bottom: rem($baseGap * 2);
	}
}

.sub-outter-img {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.after-img-sub {
	position: relative;
	padding: 4rem 0 4rem;

	@include breakpoint(tiny) {
		padding: 6rem 0 6rem;
	}

	@include breakpoint(medium) {
		padding: 8rem 0 8rem;
	}

	@include breakpoint(large) {
		padding: rem(130px) 0;
	}

	.medium-5 {
		@include breakpoint(giant) {
			padding-right: 4rem;
		}	
	}
	
	@include breakpoint(medium) {
		&:after {
			content:'';
			height: calc(100% + 8rem);
			display: block;
			background: url('/images/layout/cont-img-2.jpg') no-repeat center/cover;
			position: absolute;
			transform: translateY(-50%);
		    top: 50%;
			left: $baseGap;
			width: 53%;
		}
	}
}

body.anfahrt {
	button {
		margin-bottom: 0!important;
	}
}

.contact-data-sub {
	margin-top: 2rem;
	height: auto;

	p {
		text-align: center;
	}

	.btn {
		margin: 0 auto;
	}

	body.openimmo & {
		display: flex;
		flex-flow: column;
		align-items: center;
	}

	
	@include breakpoint(large) {
		margin: 0 auto; 
		margin-top: 4rem;
		width: 70%;
	}

	@include breakpoint(giant) {
		margin-top: 6rem;
	}
	

	.contact-data {
		display: flex;
		margin-bottom: 0;
		justify-content: center;
		flex-flow: row wrap;		

		li {

			a, span {
				font-family: $special;
			}

			strong {
				margin-right: .4rem;
			}

			@include breakpoint(cut) {
				font-size: 18px;
			}
			
			&:first-child {
				margin-right: 1rem;

				@include breakpoint(cut) {
					margin-right: rem(30px);						
				}
			}
		}
	}
}

.flex-form {
	@include breakpoint(small) {
		display: flex;
		flex-flow: row wrap;
	}
	
	label {
		position: relative;
		overflow: hidden;
		background: $green-dark;
		display: block;
		cursor: pointer;
		
		&:after{
			content:'';
			height: 100%;
			width: 100%;
			background: darken($green-dark, 2%);
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			transform: translateX(-100%);
			transition: all 300ms ease-in-out;
		}

		&:hover {
			&:after {
				transform: translateX(0);
			}
		}

		span {
			padding: 1rem;
			display: flex;
			color: $light;
			font-family: $special;
			font-weight: 500;
			text-decoration: none;
			align-items: center;
			position: relative;
			overflow: hidden;
			z-index: 9;

			@include breakpoint(tiny) {
				padding: 2rem;
			}

			@include breakpoint(small) {
				justify-content: center;
			}

			@include breakpoint(large) {
				padding: rem(35px);
				font-size: rem(20px);
			}
		}
		
		&:nth-of-type(2) {
			background: $green-medium;
			
			&:after {
				background: darken($green-medium, 3%)
			}
		}

		&:nth-of-type(3) {
			background: $green;

			&:after {
				background: darken($green, 5%)
			}

			@include breakpoint(small) {
				width: 100%;
			}

			@include breakpoint(large) {
				width: calc(100%  / 3);
			}
		}

		&:not(:last-child) {
			@include breakpoint(small) {
				width: 50%;
			}

			@include breakpoint(large) {
				width: calc(100%  / 3);
			}
		}
	}
}

input#firstForm, input#firstForm2, input#firstForm3 {
	height: 0;
	width: 0;
	line-height: 0;
	font-size: 0;
	display: none;

	&:checked {
		 * ~ .formPopup, ~ .formPopup {
			opacity: 1;
			visibility: visible;
		}

		* ~ label, ~ label {
			&:before {
				content:'';
				height: 100Vh;
				width: 100Vw;
				top: 50%;
				left: 50%;
				position: fixed;
				display: block!important;
				background: rgba($grey-dark, .9);
				transform: translate(-50%, -50%);
				z-index: 99999999999999;
				opacity: 1!important;
				visibility: visible!important;
			}		
		}
	}
}

.formPopup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: $medium;
	border-radius: 5px;
	z-index: 9999999999999999;
	opacity: 0;
	visibility: hidden;
	max-height: 80vH;
	max-width: 95vw;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 1rem;
	transition: all 300ms ease-in-out;

	@include breakpoint(large) {
		max-height: 70vH;
		padding: 2rem;
		max-width: 900px;
	}
	

	> label {
		position: fixed;
		right: 0;
		top: 0;
		padding: 1rem;
		background: $green-medium;
		border-bottom-left-radius: 5px;
		color: $light;
		font-family: $special;
		z-index: 99999;
		cursor: pointer;
		transition: all 300ms ease-in-out;

		&:hover {
			background: $green-dark;
		}
	}


	> div {
		height: 100%;
		overflow-y: scroll;
		padding: 1rem 0;

		@include breakpoint(medium) {
			padding: 1rem;
		}
		

		fieldset {
			input, select, label {
				font-size: 15px;

				@include breakpoint(large) {
					font-size: 17px;
				}
				
			}

			.flex-box {
				@include breakpoint(medium) {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
				}

				.select {
					@include breakpoint(medium) {
						width: 49%;
					}
				}

				.half-flex {
					@include breakpoint(medium) {
						width: 49%;
					}	
				}

				.showLabel {
					display: flex!important;
					align-items: center;

					input {
						margin-top: 0;
					}
				}

				&.margin-bottom {
					margin-bottom: 2rem;
				}
			}

			.checkbox-flex {
				@include breakpoint(medium) {
					padding-left: 3rem;
				}
			}

			.h5, .h6 {
				&:not(:first-of-type) {
					margin-top: 2rem!important;
					margin-bottom: 1.5rem!important;
				}

				&.margin-top {
					margin-top: 2rem!important;
					margin-bottom: 1.5rem!important;
				}
			}
		}
	}
}

label.btn {
	margin-bottom: 2rem;

	body.cmsBackend & {
		width: auto;
	}
}

body.kontaktFormularbestaetigung, body.kontaktFormularfehler {
	header {
		display: none;
	}
}





#ProvenExpert_widgetbar_container {
	img {
		width: auto !important;
	}
	p {
		margin-bottom: 0 !important;
	}
}

.iframeFlex {
	display: flex;
	align-items: center;
	padding-top: 2.75rem;

	@include breakpoint(medium) {
		padding-top: 4rem;
	}

	@include breakpoint(full) {
		padding-top: 6rem;
	}

	.widgetImmoxxl {
		text-align: center;

		iframe {
			max-width:rem(165px);
			width:100%;
			height:rem(325px);
			display: inline-block;

			@include breakpoint(medium,max) {
				max-width:rem(125px);
				height:rem(250px);
			}
		
		}	
	}
	// .widgetProvenexpert {
	// 	@include breakpoint(small,max) {
	// 		margin-top: rem(50px);
	// 	}
	// 	@include breakpoint(medium,max) {
	// 		margin-top: rem(10px);
	// 	}

	// 	#pewl {
	// 		.pew-left {
	// 			> div {
	// 				width: 100%;
	// 			}
	// 		}
	// 		.pew-go {
	// 			@include breakpoint(large,max) {
	// 				position: absolute;
	// 				bottom: 120%;
	// 				right: rem(8px);
	// 			}
	// 		}
	// 		img {
	// 			width: auto !important;
	// 			height: auto !important;
	// 		}
	// 		p {
	// 			margin-bottom: 0 !important;
	// 		}
	// 	}
		
	// }
}

.centered-cont {
	@include breakpoint(large) {
		align-items: center;
	}
	
}

.outter-breadcrumb {
	background-color: $medium;

	.breadcrumb {
		padding: 1.5rem 0 1.4rem 0 ;
		margin-bottom: 0!important;

		li {
			padding-right: 20px;
			padding-left: 0;

			&:first-of-type {
				padding-right: 20px;
				padding-left: 0;
			}

			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				font-size: 17px;
				text-indent: -10px;
			}
		}
	}
}

.headline-margin {
	margin-top: .7rem;
}

body.fuerSieVorOrt {
	main {
		img {
			margin-top: .4rem;
			margin-bottom: 2rem;
		}
	}
	.spanien {
		margin-bottom: 1rem;
		@include breakpoint (small) {
			margin-bottom: 2rem;
		}
	}
}

.abstandrow {
	margin: 1rem!important;
}

.paddingNew {
	
	@include breakpoint(medium) {
		padding: 2rem;
	}
}

.profil {
	margin-bottom: 3.5rem;
}

span.extra {
	padding: 20px 18px;
	@include breakpoint(giant) {
	padding: 20px 50px;
	}
}

@include breakpoint(large) {
.box-grau {
	height: 100%!important;
}

img.noMargin {
	margin-bottom: 0!important;
}

}



.profil-name {
	margin-bottom: 0!important;
}

.paddingNew {
	@media (min-width: 992px) {
		padding: 1rem!important;
	}
	@media (min-width: 1090px) {
		padding: 2rem!important;
	}
	@media (min-width: 1184px) {
		padding: 4rem 2rem!important;
	}	
}


.pep-box {
	background: #e5e5e5;
	padding: 2rem;
	border-radius: 4px;
}
.siegel{

	img{
		display: flex;
		justify-content: center;
		margin:auto;
		width: 80%;
		height:auto;	
		@include breakpoint(tiny) {
			width: 40%;
		}
		@include breakpoint(small) {
			width: 50%;
		}
		@include breakpoint(medium) {
			width:  35%;
		}
		@include breakpoint(large) {
			 width: 25%;
		}
		@include breakpoint(giant) {
			width: 80%;
			margin-top: 5rem;
		}
	}
}

.pepp{
	padding: 2rem;
}

.pep-boxx {
	background-color: #698824;
	position: relative;

	&:before {
		@include breakpoint(full) {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 100%;
			left: calc(100% - 50vw);
			background-color: #698824;
		}
	}
}

.header-row {
}

.pep-img {
	width: 30%;
	height: auto;
}

.immowelt {
	display: flex;
	justify-content: center;
	a{
		width: 50%;
		height: auto;
		border: none;
		@include breakpoint(tiny) {
			width: 30%;
		}
		@include breakpoint(small) {
			width: 30%;
		}
		@include breakpoint(medium) {
			width: 35%;
			margin-left: 7rem;
			padding-top: 2rem;
		}
		@include breakpoint(large) {
			width: 30%
		}
		@include breakpoint(giant) {
			width: 25%;
		}
	}	
}

.bvfi {
	display: flex;
	justify-content: center;
	a{
		width: 45%;
		height: auto;
		padding-bottom: .9rem;
		@include breakpoint(tiny) {
			width: 30%
		}
		@include breakpoint(small) {
			width: 30%;
			height: auto;
		}
		@include breakpoint(medium) {
			width: 55%;
			height: auto;
			margin-top: 1.3rem;
			margin-right: 4rem;
			padding-top: 2rem;
		}
		@include breakpoint(medium-xl) {
			width: 60%;
			margin-right: 10rem;
		}
		@include breakpoint(xs-large) {
			width: 70%;
		}
		@include breakpoint(large) {
			width: 45%;
			margin-right: 10rem;
			margin-top: 1rem;
		}
		@include breakpoint(giant) {
			width: 35%
		}
	}
}

.haus-hand {
	-webkit-filter: invert(100%);
	filter: invert(100%);
	
	@include breakpoint(medium) {
		margin-bottom: 1.3rem!important;
	}
}

.weiss {
	@include breakpoint(medium) {
		margin-bottom: 1.3rem!important;
	}
}

.proven-abstand { 

	margin-bottom: .5rem;
	
}
.elem1 { 

	order: 2; 
	@include breakpoint(small) {
		order: 1;
	}
}
.elem2 { 
	order: 1; 
	@include breakpoint(small) {
		order:2;
	}
	@include breakpoint(medium) {
		margin:0;
	}
}

.pew-middle{
	padding-bottom: 11rem!important;
}

.flex-this {
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 3rem 0;
	margin: auto;
	margin-bottom: 1rem;
	background-color: #575b60;
	align-items: center !important;
}


.marginTop{
	margin-top: 2rem;
	display: flex;
	justify-content: center;

}
.bild{
	height: 200px;
	object-fit: contain;
}

.logoCarousel {
	.swiper-wrapper {
		display: flex;
		align-items: stretch;
	}

	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;

		&, body:not(.cmsBackend) & {
			height: auto;
		}
	}
}