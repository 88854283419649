/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.0-BETA_DEV
* @date February 12, 2020
*/

/**
* Place your custom styles for this project ONLY here 
* Tip: !important can become helpful in some cases :)
*/

body.openimmo {
    #immo_notification,#contSearch,#contBookmarks {
        z-index: 999999999 !important;
    }

    // in case you want to adjust the space between rows
    .space {
        // @include space(50px);
    }

    &.index {
        .immo_summary {
            margin-bottom: 0 !important;
        }
    }

    .immo_summaryReferenz {
        @extend .marginBottom;
        position: relative;

        @include breakpoint(medium) {
            margin-bottom: 0!important;
        }
        

		.cover {
			position: relative;
			height: 0;
            padding-top: 66.75%;
            position: relative;
            margin: 0;
            margin: 0 0 rem($baseGap) 0;

			.imageCtn {
				display: block;
				margin: 0 0 rem($baseGap) 0;
				border-radius: 0;
				overflow: hidden;

				position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            
			img {
				@include object-fit(cover, center center);
				height: 100%;
			}

			.immo_objectBadges {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 100;
				display: block;
				width: auto;

				mark {
					background: $dark;
					text-transform: uppercase;
					font-size: rem(11px);
					line-height: rem(30px);
					letter-spacing: 2px;
					height: rem(40px);
					font-weight: bold;
					color: $light;
					padding: rem(5px) rem(10px);
					display: inline-block;

                    font-family: $special;
                    font-size: rem(17px);
                    color: $light;
                    text-transform: none;
                    font-weight: 300;
                    letter-spacing: normal;
                    height: 67px;
                    padding: 0 25px;
                    border-bottom-right-radius: 5px;
                    display: block;
                    line-height: 67px;

                    
					&:nth-child(odd) {
						background: darken($dark,5%);
					}
				}
			}
        }
    
    }

    // Object preview box
    .immo_objects {
        .immo_summary {

            .cover {
                // Bookmark Icon ( The star icon in the overview )
                .immo_bookmark {
                    // background: $secondary;
                    border-bottom-left-radius: 5px;

                    svg {
                        path {
                            // fill: transparent;
                            // stroke: $light;
                        }
                    }
                    &.active {
                        svg {
                            path {
                                // fill: $light;
                                // stroke: transparent;
                            }
                        }
                    }
                    &:hover, &:focus {
                        svg {
                            path {
                                // fill: $light;
                            }
                        }
                    }
                }
                .immo_objectBadges {
                    mark {
                        font-family: $special;
                        font-size: rem(17px);
                        color: $light;
                        text-transform: none;
                        font-weight: 300;
                        letter-spacing: normal;
                        height: 67px;
                        padding: 0 25px;
                        border-bottom-right-radius: 5px;
                        display: block;
                        line-height: 67px;
                    }
                }
            }
            .details {
                .infos {
                    font-family: $special;
                    padding: rem(20px) rem(40px) rem(20px) rem(40px) !important;
    
    
                    .tags {
                        mark {
                            // background: $primary;
                        }
                    }

                    .immo_tags {
                        display: none;
                    }

                    .objekttitel, h2, .h2 {
                        color: #000000!important;
    
                        body.index & {
                            @include breakpoint(giant) {
                                padding: rem(65px) rem(65px) 0 rem(65px);
                            }
                        }
                    }
    
                    .location {
                        p {
                            color: #797c80;
                            font-weight: 900;

                            &:before {
                                @extend .icon;
                                @extend .icon-pin;
                                color: #b6b6b6;
                            }
                        }
                    }
    
                    > p {
                        color: #000000;
                        font-weight: 500;
                        text-align: right;
                        margin-top: rem($baseGap) !important;
                        margin-bottom: 0!important
                    }
                } // .details.infos
    
                .openimmoTableCtn {
                    .openimmoTable {
                        tbody {
                            tr {
                                td {
                                    color: #000000;
    
                                    span {
                                        &.before {
                                            &:before {
                                                @extend .icon;
                                                @extend .icon-house;
                                                color:#b6b6b6;
                                            }
                                            &.zimmer {
                                                &:before {
                                                    @extend .icon-doors;
                                                }
                                            }
                                            &.qm {
                                                &:before {
                                                    @extend .icon-qm;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                } //.openimmoTableCtn
                .btn {
                    background: $green-btn;
                    border: none;
                    color: $light;
                    cursor: pointer;
                    display: inline-block;
                    font-size: rem($baseFontSize);
                    line-height: rem($baseLineHeight);
                    padding: 0.8rem 1.3rem;
                    text-align: center;
                    text-decoration: none;
                    font-family: $special;
                    font-weight: 500;
    
                    @include breakpoint(medium) {
                        font-size: rem(20px);
                    }
    
                    @include breakpoint(cut) {
                        padding: 20px 60px;
                    }
    
                    &:hover,
                    &:active,
                    &:focus {
                        background: $grey-dark;
                        color: $light;
                    }
                }
            } // .details
        } //.immo_summary
    } //.immo_objects

    // Detail view of an object
    #immo_detail {
        .titleEnergiepass {
            font-size: rem(40px);
            line-height: rem(68px);
        }
        dt, dd {
            // background: darken($light, 3%);
        }    
    } //#immo_detail

    // Favourite objects at the top in the request page ("Merkzettel")
    .immo_saved {
        h1 {

        }
        .immo_objects {

        }
    } //.immo_saved

    // Contact formular on the request page ("Merkzettel")
    .immo_saved_request {
        form.immo_request {

        }
    } //.immo_saved_request

} //body.openimmo

// Default fixed Buttons on the right (Not needed if using custom labels of e.holtkamp)
#openimmoFixedButtons {
    ul {
        li {
            // You can define here custom icons for the fixed Buttons
            label:before {
                // font-family: $iconFont;
                // font-size: 2em;
            }
            &.search {
                label:before {
                    // content: map-get($iconMap, search);
                }
            }
            &.bookmarks {
                label:before {
                    // content: map-get($iconMap, bookmark);
                }
            }
        }
    }
}

// Quick access sidebars
#contSearch, #contBookmarks {
    // Use this if there is a bar on the bottom which should stay visible when the quick access sidebar has been opened on mobile (e.g. quickbar is on bottom)
	// &, .close:after {
	// 	@include breakpoint($break, max) {
	// 		height: calc(100vh - #{rem($barHeight)});
	// 	}
	// }
}