/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 34px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Ubuntu", sans-serif;

$displayFont: "Ubuntu", sans-serif;

$special: "Ubuntu", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #726A5B;
$secondary: #EDE8DD;
$light: #fff;
$medium: #e5e5e5;
$dark: #575b60;
$darker: #4d4d4d;
$border: #bbb;
$alert: #D85D00;
$green: #698824;
$green-2: #77a907;
$green-dark: #273310;
$green-medium: #364713;
$green-btn: #536c1b;
$grey-light: #f2f2f2;
$grey-light2: #f7f7f7;
$grey-light3: #bcbcbc;
$grey-medium: #e1e1e1;
$grey-medium-2: #888888;
$grey-dark: #575b60;
$grey-list: #949494;

$baseGap: 18px;

$rowMaxWidth: 1200px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	video: "\eb36", 
	doors: "\e900", 
	qm: "\e901", 
	house: "\e902", 
	minus: "\e9a3", 
	plus: "\e9be", 
	times: "\ea12", 
	star: "\f006", 
	lupe: "\f002", 
	pin: "\f041", 
	check: "\f05d", 
	exclamation: "\f06a", 
	angle-left: "\f104", 
	angle-right: "\f105", 
	angle-up: "\f106", 
	angle-down: "\f107", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (3, 4, 6, 7, 8, 10, 9, 11, 12),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1),
		suffix: (1),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (0),
		suffix: (0, 3, 1),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1),
		suffix: (1),
		flex: (display:flex),
		center: (align-items:center),
	),
	medium-xl: (
		width: em(850px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1),
		suffix: (1),
	),
	xs-large: (
		width: em(930px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		suffix: (1),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		suffix: (1),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		suffix: (1),
		prefix: (1, 2),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	fuller: (
		width: em(1820px, $bpContext), 
	),
);

