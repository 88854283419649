/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.0-BETA_DEV
* @date February 12, 2020
*/

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/

#quickAccess {
    position: fixed;
    bottom: 0;
    z-index: 30001;
    text-align: center;
    width: 100%;
    visibility: hidden;

    li {
        display: inline-block;

        label {
            @extend .btn;
            padding: 0 !important;
            visibility: visible;
            width: 3.4em;
			height: 3.4em;
            line-height: 3.4em !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    }
}

// FIXED ELEMENTS
//////////////////////////////

#fixSearch, #fixBookmarks, #fixNews {
	display: none;
}

.contAside {
	//@extend %animatedTransform;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	max-width: rem(650px);
	z-index: 9999930000;
	height: 100%;
	transform: translateX(100%);

	@include breakpoint(medium) {
		height: auto;
		top: 5%;
		max-width: rem(550px);
	}

	@include breakpoint(giant) {
		top: 7%;
		max-width: rem(600px);
	}


	@include breakpoint(fuller) {
		top: 10%;
		max-width: rem(650px);
	}

	#searchForm {
		fieldset {
			border: 0;
			padding: 0;
			margin: 0;

			.flex-it {
				display: flex;
		        flex-flow: row wrap;
		        justify-content: space-between;
		        
		        .outter-half {
		            @include breakpoint(medium) {
		                width: 49%;
		            }
		        }
			}


	        input {
	            width: 100%;
	        }

		}
		.btn {
			margin: rem($baseGap) 0 0 0;
		}
	}


	h1, h2, h3 {
		span {
			display: block;
			font-size: 80%;
		}
	}

    p {
        @extend .marginBottom;
    }

	.close {
		//@extend %animatedTransform;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 3000;
		visibility: hidden;
		opacity: 0;

		&:hover {
			&:before {
				background: $grey-dark; 
			}
		}

		@include breakpoint(medium) {
			box-shadow: -1px -1px 15px rgba(0,0,0,0.35);
			border-top-left-radius: 5px;
		}
		

		&:before {
			@extend .icon;
			@extend .icon-times;
			width: 3.5em;
			height: 3.5em;
			line-height: 3.5em;
			background: $green-btn;
			text-align: center;
			color: $light;
			font-size: rem(15px);
			border-bottom-left-radius: 5px;		
			//transition: all 300ms ease-in-out;

			@include breakpoint(medium) {
				border-top-left-radius: 5px;		
			}
		}
		
		@include breakpoint(medium) {
			right: 100%;
			left: auto;

			&:before {
				font-size: rem(20px);
			}
			&:after {
				left: auto;
				right: 0;
			}
		}

		span {
			display: none;
		}
	}
	> div {
		overflow-y: auto;
		height: 100%;
		min-height: rem(600px);
		padding: 2rem;
		color: $dark;
		position: relative;
		z-index: 2;
		background: $light;
		box-shadow: 0;

		@include breakpoint(medium) {
			padding: 3rem;
			border-bottom-left-radius: 5px;
		}
		

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6 {
			color: $darker;
			margin-bottom: 1rem;
			font-size: 22px;
			font-family: $special;
			font-weight: 500;
			
			@include breakpoint(cut) {
				font-size: 36px;
				margin-bottom: 2.5rem;
			}
		}

		&.loading {
			pointer-events: none;			
			position: relative;
			&:before {
				background: url("/images/layout/loading.svg") no-repeat center center;
				display: block;
				content: '';
				z-index: 9999;
				position: absolute;
				top: 0;
				z-index: 9999;
				width: 100%;
				height: 100%;
				overflow: hidden;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

	
		.btn {
			width: 100%;

			&.marginBottom {
				margin-bottom:.7rem!important;
			}
		}
	}
}

#fixSearch {
	&:checked {
		~ #contSearch {
			transform: translateX(0);

			>div {
				box-shadow: -1px -1px 15px rgba(0,0,0,0.35);
			}

			.close {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
#fixBookmarks {
	&:checked {
		~ #contBookmarks {
			transform: translateX(0);

			>div {
				box-shadow: -1px -1px 15px rgba(0,0,0,0.35);
			}

			.close {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
#fixNews {
	&:checked {
		~ * #contNews {
			transform: translateX(0);

			.close {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

#immoBookmarkSide {
	padding-top: rem($baseGap);

	.object {
		margin-bottom: rem($baseLineHeight + $baseGap);
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		background: darken($light, 6%);
		position: relative;

		div {
			width: 100%;

			&.coverPhoto {
				position: absolute;
                top: 0;
                left: 0;

				@include breakpoint(tiny) {
					width: 40%;
					height: 100%;
				}

				img {
					@include object-fit(cover, center center);
					height: 100%;	
					// transform: translateY(rem($baseGap * -1));
				}
			}

			&.info {
				padding: rem($baseGap);
				margin-left: 40%;
				width: 60%;
				height: 100%;
				min-height: rem(150px);
				display: flex;
				justify-content: center;
				flex-flow: column;
				
				span {
					font-size: 0.7rem;
					line-height: 1.4rem;
					color: $dark !important;
					display: block;

					span {
						display: inline-block!important;
					}
					
					&.title {
						@extend .h4;
						font-size: 20px!important;
						margin-bottom: 0.2em !important;
					}
				}
			}
		}
	}
}

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/
