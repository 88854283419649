* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  font-weight: 300;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;

  strong {
    font-weight: 500;
  }

  &.preload * {
    transition: none !important;
  }
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  font-weight: 300;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  font-size: rem(26px);

  span {
    font-weight: 300;
    display: block;
    line-height: 1.3em;
    font-size: rem(19px);
    margin-top: .2rem;

    @include breakpoint(tiny) {
      font-size: rem(21px);
    }

    @include breakpoint(cut) {
      line-height: 1.4em;
      font-size: rem(28px);
    }
  }

  @include breakpoint(tiny) {
    font-size: rem(32px);
  }

  @include breakpoint(cut) {
    font-size: rem(72px);

    body:not(.index) & {
      font-size: rem(45px);
    }
  }

  &.h5 {
    font-family: $special;
    line-height: 1.3em;
    font-weight: 500;
    font-size: rem(18px)!important;

    @include breakpoint(medium) {
      font-size: rem(22px)!important;
    }

    span {
      font-weight: 300;
      font-size: 18px;
      display: block;
    }
  }
}

h2,
.h2 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  font-size: rem(23px);

  span {
    font-weight: 300;
    display: block;
    line-height: 1.3em;
    font-size: rem(19px);
    margin-top: .2rem;

    @include breakpoint(cut) {
      line-height: 1.4em;
      font-size: rem(36px);

      body:not(.index) & {
        font-size: rem(32px);
      }
    }
  }

  @include breakpoint(medium) {
    font-size: rem(32px);
  }

  @include breakpoint(large) {
    font-size: rem(40px);
  }

  @include breakpoint(cut) {
    font-size: rem(48px);

    body:not(.index) & {
      font-size: rem(42px);
    }
  }

  &.margin-big {
    @include breakpoint(large) {
      margin-bottom: 3.5rem!important;
    }
  }
}

h3,
.h3 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  font-size: rem(20px);

  span {
    font-weight: 300;
    display: block;
    line-height: 1.3em;
    font-size: rem(17px);
    margin-top: .2rem;

    @include breakpoint(medium) {
      font-size: rem(22px);
    }
    

    @include breakpoint(cut) {
      line-height: 1.4em;
      font-size: rem(30px);
    }
  }

  @include breakpoint(medium) {
    font-size: rem(28px);
  }

  @include breakpoint(cut) {
    font-size: rem(36px);
  }
}

h4,
.h4 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  font-size: rem(19px);

  span {
    font-weight: 300;
    display: block;
    line-height: 1.3em;
    font-size: rem(17px);
    margin-top: .2rem;

    @include breakpoint(medium) {
      font-size: rem(18px);
    }
    

    @include breakpoint(cut) {
      line-height: 1.4em;
      font-size: rem(20px);
    }
  }

  @include breakpoint(medium) {
    font-size: rem(25px);
  }
}

h5,
.h5 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  font-size: rem(18px);

  @include breakpoint(medium) {
    font-size: rem(22px);
  }

  span {
    font-weight: 300;
    font-size: 18px;
    display: block;
  }
  
}

h6,
.h6 {
  font-family: $special;
  line-height: 1.3em;
  font-weight: 500;
  color: darken($grey-medium, 20%);
  font-size: rem(17px);

  @include breakpoint(medium) {
    font-size: rem(20px);
  }
}

/**
 * Links
 */
a {
  color: $green;
  word-wrap: break-word;
  transition: all 300ms ease-in-out;
  text-decoration: none;

  &:not(.btn) {
    color: $green-2;

    &:hover {
      color: darken($green-2, 10%);
    }
  }

  &:focus,
  &:hover,
  &:active {
    color: $green-medium;
  }

  img {
    border: none;

    &.orginal-img {
      width: auto;
    }
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 2rem 0;
  flex-basis: 100%;

  @include breakpoint(small) {
    margin: 3rem 0;
  }

  @include breakpoint(giant) {
    margin: 4.5rem 0;
  } 

  &.smaller {
    @include breakpoint(giant) {
      margin: 3rem 0;
    } 
  } 

  &.no-margin {
    margin: 0;
  }
}

ul,
ol {
  list-style: none;

  body:not(.cmsBackend) & {
    &.split {

      @include breakpoint(medium) {
        columns: 2;
      }
    }  
  }

  &.strong-before {
    strong {
      margin-right: .8rem;
    }
  }

  &.styledList {
    li {
      padding-left: rem(35px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-check;
        font-weight: 300;
        color: $grey-list;
        text-indent: rem(-35px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }

  &.flex-list-auto {
    dt, dd {
      width: auto;

      &.wide {
        width: 100%;
      }

      &.margin-bottom{
        margin-bottom: 1.5rem;
      }
    }

    dt {
      font-weight: 500;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
        font-family: $displayFont;
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}


h2{
  &.h4 {
    font-size: rem(19px)!important;

    span {
      font-size: rem(17px)!important;

      @include breakpoint(medium) {
        font-size: rem(18px)!important;
      }
      

      @include breakpoint(cut) {
        line-height: 1.4em;
        font-size: rem(20px)!important;
      }
    }

    @include breakpoint(medium) {
      font-size: rem(25px)!important;
    }
  }

  &.h3 {
  font-size: rem(20px)!important;

  span {
    font-size: rem(17px)!important;

    @include breakpoint(medium) {
      font-size: rem(22px)!important;
    }
    
    @include breakpoint(cut) {
      line-height: 1.4em;
      font-size: rem(30px)!important;
    }
  }

  @include breakpoint(medium) {
    font-size: rem(28px)!important;
  }

  @include breakpoint(cut) {
    font-size: rem(36px)!important;
  }
  }
}

#ProvenExpert_widgetbar_container {
  @include breakpoint(large,max) {
    display: none;
    height: 0;
  }
  @include breakpoint(large) {
    padding-top: rem(43px);
    text-align: center;
    * { box-sizing: border-box; }
  }

  .agreement_provenexpert {
    padding-top: rem($baseGap);
    display: block;
  }
  .agreement_desc {
    display: block;
    padding-bottom: rem($baseGap*3);
  }
}