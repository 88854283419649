// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $medium; // Navigation Background
$navColor: $darker; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	font-family: $special;
	font-weight: 500;
	color: $darker;

	@include breakpoint($break) {
		font-size: rem(19px);
		padding: rem(35px) 0;

		body:not(.index) & {
			padding: rem(15px) 0;
		}
	}
}
%buttonHover {
	// Link Hover
	color: $green;
}

%buttonActive {
	// Link Active
	color: $green;
}

%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
	margin-left: .9rem;

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	margin-left: .9rem;

	@include breakpoint($break) {
		margin-left: .9rem;
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 84.59px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $darker; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	transition: all 300ms ease-in-out;
	min-width: 80%;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}

					&.videos {
						> span {
							justify-content: flex-start !important;

							&:before {
								@extend .icon;
								@extend .icon-video;
								margin-right: 0.25em;
								font-size: 1.25em;
	
								@include breakpoint($break) {
									margin-right: 0.125em;
									@include breakpoint(giant, max) {
										font-size: 1em;
									}
								}
							}

							&:after {
								margin-left: auto !important;
							}
						}
					}

					@include breakpoint($break, max) {
						&:not(:last-child) {
							border-bottom: 1px solid lighten($grey-dark, 41%);
						}
					}

					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: $green;
								color: $light;
								font-family: $special;
								font-weight: 500;
							}

							.videos {
								display: none;
							}
						}
					}
					@include navBack {
						background: lighten($grey-dark, 41%);
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	
	#navigation {
		transition: all 300ms ease-in-out;


		@include breakpoint(large) {
			top: rem(100px);
		}

		@include breakpoint(cut) {
			top: rem(119px);
		}

		div {
			max-width: rem($rowMaxWidth);
			margin: 0 auto;
			width: 100%;
			padding: 0 rem($baseGap);
		}

		body:not(.index) & {
			top: rem(117px);
			// height: 72px;

			@include breakpoint(large) {
				top: 5.5rem;
			}
			@include breakpoint(cut) {
				top: rem(117px);
			}
		}

		&.smaller {
			border-bottom: 1px solid darken($navBg, 7%);

			@include breakpoint(cut) {
				top: rem(117px);
			}
			

			nav {
				ul {
					&.navi {
						li {
							a, span, label {
								padding: 15px 0;
							}
						}
					}
				}
			}
		}

		nav {
			ul {
				&.navi {
					li {
						&.home {
							@include breakpoint(giant, max) {
								display: none;
							}
						}
						&.hasSub {
							span {
								&:after {
									margin-left: .45rem!important;
									top: 0.075em;
									position: relative;
								}
							}

							.sub {
								background: $grey-dark;
								left: 50%;
								transform: translateX(-50%);

								li {
									> a, span, > label {
										padding: .7rem 1.5rem;
										color: $grey-light3;
										font-weight: 300;
										font-size: 17px;
									}

									&:hover, &.active {
										> a, >span, > label {
											color: $grey-light;
										}
									}


									&:not(:last-child) {
										border-bottom: 1px solid darken($grey-light3, 20%);
									}

									&.hasSub {
										span {
											&:after {
												transform: rotate(0);
												margin-left: .7rem;
												font-size: .8em;
											}
										}
									}

									.sub {
										left: 100%;
										transform: translateX(0);
										border-left: 1px solid darken($grey-light3, 20%);
									}
								}
							}
						}
						&.navBack {
							display: none;
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	box-shadow: 3px -1px 12px 0px rgba(0,0,0,0.4);
	height: 4.7rem;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		img {
			height: rem(50px);
			width: auto;
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	justify-content: center;

	li {
		margin-right: 1rem;
	}
}